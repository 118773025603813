<template>
    <form @submit.prevent>
        <!-- ФИО и Телефон -->
        <div class="form__group">
            <v-text-field
              v-model="name"
              @input="checkForm($v.name)"
              @blur="nextElementfocus('name', 'phone')"
              @keyup.enter="$event.target.blur()"
              :class="{ invalid: $v.name.$dirty && !$v.name.required }"
              :label="prefix + '_name' | localize"
              :placeholder="prefix + '_requeired' | localize"
              :hint=" ($v.name.$dirty && !$v.name.required ? prefix + '_requeired' : 'empty') | localize"
              ref="name"
              outlined
            ></v-text-field>

            <v-text-field
              v-model="phone"
              type="tel"
              v-mask.hide-on-empty="phoneMask"
              @input="checkForm($v.phone)"
              class="v-input-phone"
              :class="{invalid: $v.phone.$dirty && (!$v.phone.required || !$v.phone.minLength)}"
              :label="prefix + '_phone' | localize"
              :placeholder="prefix + '_requeired' | localize"
              :hint="($v.phone.$dirty && !$v.phone.required ? prefix + '_requeired' : 'empty') | localize"
              ref="phone"
              outlined
            ></v-text-field>
        </div>

        <!-- E-mail и Город -->
        <div class="form__group">
            <v-text-field
              v-model="email"
              type="email"
              @input="checkForm($v.email)"
              :class="{
                invalid:
                  ($v.email.$dirty && !$v.email.required) ||
                  ($v.email.$dirty && !$v.email.email),
              }"
              :label="prefix + '_email' | localize"
              placeholder="name@bankimonline.com"
              :hint="hintArray(
                $v.email, [
                  {rule: $v.email.required, message: 'form_reqiered_email'},
                  {rule: $v.email.alpha, message: 'form_wrong_abc_email'},
                  {rule: $v.email.email, message: 'form_wrong_email'}
                ]
              ) | localize"
              ref="email"
              outlined
            ></v-text-field>

            <v-autocomplete
              v-model="city"
              :items="cities"
              @change="checkForm($v.city)"
              :class="{ invalid: $v.city.$dirty && !$v.city.required }"
              item-text="name"
              item-value="value"
              cache-items
              :label="prefix + '_city' | localize"
              :placeholder="'select' | localize"
              ref="city"
              outlined
            ></v-autocomplete>
        </div>

        <!-- ИНН / Юр. лицо -->
        <div class="form__group">
            <v-text-field
              v-model="inn"
              v-mask.hide-on-empty="innMask"
              @input="checkForm($v.inn)"
              :class="{invalid: $v.inn.$dirty && (!$v.inn.required || !$v.inn.minLength)}"
              :label="prefix + '_inn' | localize"
              :placeholder="prefix + '_requeired' | localize"
              :hint=" ($v.inn.$dirty && !$v.inn.required ? prefix + '_requeired' : 'empty') | localize"
              ref="inn"
              outlined
            ></v-text-field>

            <v-select
                v-model="entity"
                :items="getList(prefix + '_list_1_', 3)"
                :class="{ invalid: $v.entity.$dirty && !$v.entity.required }"
                item-text="name"
                item-value="value"
                :label="prefix + '_name2' | localize"
                :placeholder="'select' | localize"
                :menu-props="{ offsetY: true }"
                append-icon="icon-arrow-down"
                ref="entity"
                outlined
            ></v-select>
        </div>
        
        <!-- Город филиала / Источник дохода -->
        <div class="form__group">
            <v-autocomplete
              v-model="branch_city"
              :items="cities"
              @change="checkForm($v.branch_city);"
              :class="{ invalid: $v.branch_city.$dirty && !$v.branch_city.required }"
              item-text="name"
              item-value="value"
              cache-items
              :label="prefix + '_city2' | localize"
              :placeholder="'select' | localize"
              ref="branch_city"
              outlined
            ></v-autocomplete>

            <v-select
                v-model="source"
                :items="getList(prefix + '_list_2_', 5)"
                :class="{ invalid: $v.source.$dirty && !$v.source.required }"
                item-text="name"
                item-value="value"
                :label="prefix + '_source' | localize"
                :placeholder="'select' | localize"
                :menu-props="{ offsetY: true }"
                append-icon="icon-arrow-down"
                ref="source"
                outlined
            ></v-select>
        </div>

        <!-- Средний доход / Сколько лет на рынке -->
        <div class="form__group">
            <v-text-field
              v-model="income"
              class="input_number"
              inputmode="numeric"
              pattern="[0-9]*"
              @keypress="onKeyPressHandler"
              @keyup="onKeyUpPriceHandler('income', income)"
              :class="{ invalid: $v.income.$dirty && !$v.income.required }"
              :hint="($v.income.$dirty && !$v.income.required ? prefix + '_requeired' : 'empty') | localize"
              :label="prefix + '_source2' | localize"
              :placeholder="prefix + '_requeired' | localize"
              append-icon="icon-₪"
              ref="income"
              outlined
            ></v-text-field>

            <v-text-field
              v-model="years"
              class="input_number"
              inputmode="numeric"
              pattern="[0-9]*"
              @blur="nextElementfocus('years', 'clients')"
              @keyup.enter="$event.target.blur()"
              @keypress="onKeyPressHandler"
              :class="{ invalid: $v.years.$dirty && !$v.years.required }"
              :hint="($v.years.$dirty && !$v.years.required ? prefix + '_requeired' : 'empty') | localize"
              :label="prefix + '_years' | localize"
              :placeholder="prefix + '_requeired' | localize"
              ref="years"
              outlined
            ></v-text-field>
        </div>

        <!-- Информация о клиентах -->
        <div class="form__group">
            <v-text-field
              v-model="clients_month"
              class="input_number"
              inputmode="numeric"
              pattern="[0-9]*"
              @blur="nextElementfocus('clients_month', 'clients_year')"
              @keyup.enter="$event.target.blur()"
              @keypress="onKeyPressHandler"
              :class="{ invalid: $v.clients_month.$dirty && !$v.clients_month.required }"
              :hint="($v.clients_month.$dirty && !$v.clients_month.required ? prefix + '_requeired' : 'empty') | localize"
              :label="prefix + '_clients' | localize"
              :placeholder="prefix + '_requeired' | localize"
              ref="clients_month"
              outlined
            ></v-text-field>
        </div>

        <!-- Информация о клиентах -->
        <div class="form__group">
            <div class="form__element">
                <div class="form__text">{{ prefix + '_clients2' | localize }}</div>
                <v-text-field
                  v-model="clients_year"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @blur="nextElementfocus('clients_year', 'clients_year_2')"
                  @keyup.enter="$event.target.blur()"
                  @keypress="onKeyPressHandler"
                  :class="{ invalid: $v.clients_year.$dirty && !$v.clients_year.required }"
                  :hint="($v.clients_year.$dirty && !$v.clients_year.required ? prefix + '_requeired' : 'empty') | localize"
                  :placeholder="prefix + '_requeired' | localize"
                  ref="clients_year"
                  outlined
                ></v-text-field>
            </div>

            <div class="form__element">
                <div class="form__text">{{ prefix + '_clients3' | localize }}</div>
                <v-text-field
                  v-model="clients_year_2"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @blur="nextElementfocus('clients_year_2', 'income_2')"
                  @keyup.enter="$event.target.blur()"
                  @keypress="onKeyPressHandler"
                  :class="{ invalid: $v.clients_year_2.$dirty && !$v.clients_year_2.required }"
                  :hint="($v.clients_year_2.$dirty && !$v.clients_year_2.required ? prefix + '_requeired' : 'empty') | localize"
                  :placeholder="prefix + '_requeired' | localize"
                  ref="clients_year_2"
                  outlined
                ></v-text-field>
            </div>
        </div>

        <!-- Средний заработок -->
        <div class="form__group">
            <div class="form__element">
                <div class="form__text form__text_height_auto">{{ prefix + '_sourse3' | localize }}</div>
                <v-text-field
                  v-model="income_2"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @keypress="onKeyPressHandler"
                  @keyup="onKeyUpPriceHandler('income_2', income_2)"
                  :class="{ invalid: $v.income_2.$dirty && !$v.income_2.required }"
                  :hint="($v.income_2.$dirty && !$v.income_2.required ? prefix + '_requeired' : 'empty') | localize"
                  :placeholder="prefix + '_requeired' | localize"
                  append-icon="icon-₪"
                  ref="income_2"
                  outlined
               ></v-text-field>
          </div>
        </div>

        <p class="question">{{ prefix + '_jud' | localize }}</p>
        <v-radio-group
            v-model="jud1"
            v-on:change="checkForm()"
            :class="{ invalid: $v.jud1.$dirty && !$v.jud1.required }"
            ref="jud1"
            row
        >
            <v-radio
              :label="prefix + '_yes' | localize"
              value="1"
              on-icon="icon-radio-on"
              off-icon="icon-radio-off"
            ></v-radio>
            <v-radio
              :label="prefix + '_no' | localize"
              value="0"
              on-icon="icon-radio-on"
              off-icon="icon-radio-off"
            ></v-radio>
        </v-radio-group>

        <p class="question">{{ prefix + '_jud2' | localize }}</p>
        <v-radio-group
            v-model="jud2"
            v-on:change="checkForm()"
            :class="{ invalid: $v.jud2.$dirty && !$v.jud2.required }"
            ref="jud2"
            row
        >
            <v-radio
              :label="prefix + '_yes' | localize"
              value="1"
              on-icon="icon-radio-on"
              off-icon="icon-radio-off"
            ></v-radio>
            <v-radio
              :label="prefix + '_no' | localize"
              value="0"
              on-icon="icon-radio-on"
              off-icon="icon-radio-off"
            ></v-radio>
        </v-radio-group>

        <!-- Что-то еще -->
        <div class="form__group">
            <v-textarea
              v-model="text"
              :class="{ invalid: $v.text.$dirty && !$v.text.required }"
              :hint="($v.text.$dirty && !$v.text.required ? prefix + '_requeired' : 'empty') | localize"
              :label="prefix + '_else' | localize"
              :placeholder="prefix + '_requeired' | localize"
              ref="text"
              outlined
              auto-grow
            ></v-textarea>
        </div>

        <div class="error__container error__container_button">
            <p class="error" v-if="error">{{ error | localize }}</p>
            <p class="error" v-if="!success && check_form">{{ 'field_required' | localize }}</p>
        </div>

        <button type="button" class="button button__form" :class="{ button__form_disabled: !success }" @click="submitHandler()">
            {{ prefix + '_send' | localize }}
        </button>
    </form>
</template>

<script>
import { email, required, minLength } from 'vuelidate/lib/validators'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'

export default {
  name: 'Questionnaire',
  mixins: [formMixin, commonMixin, numberMixin],
  data: () => ({
    cities: [],
    phoneMask: [/0/, /5|7/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    innMask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    menu: false,

    name: null,
    phone: null,

    email: null,
    city: null,

    inn: null,
    entity: null,

    branch_city: null,
    source: null,

    income: null,
    years: null,

    clients_month: null,
    clients_year: null,
    clients_year_2: null,

    income_2: null,
    text: null,

    jud1: null,
    jud2: null,
    error: null
  }),
  props: {
    prefix: { type: String, required: true },
    service: { type: String, required: true },
    nextlink: { type: String, required: true }
  },
  mounted () {
    const localeName = 'name_' + this.$store.state.lang
    this.axios
      .get('cities?sort=population&projection=id,' + localeName)
      .then((response) => {
        this.cities = response.data.map((e) => ({
          name: e[localeName],
          value: e.id
        }))
      })
      .catch(() => {
        this.error = 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
  },
  validations: {
    name: { required },
    phone: { required, minLength: minLength(12) },
    email: { required, email, alpha: val => /^[a-z]/i.test(val) },
    city: { required },
    inn: { required, minLength: minLength(7) },
    entity: { required },
    branch_city: { required },
    source: { required },
    income: { required },
    years: { required },
    clients_month: { required },
    clients_year: { required },
    clients_year_2: { required },
    income_2: { required },
    text: { required },
    jud1: { required },
    jud2: { required }
  },
  methods: {
    nextElementfocus (current, next) {
      this.$v[current].$touch()
      if (!this.$v[current].$invalid) {
        this.$refs[current].blur()
        setTimeout(() => { this.$refs[next].focus() }, 100)
      }
    },
    submitHandler () {
      this.check_form = true

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      const dataObj = {
        name: this.name,
        phone: this.phone,
        email: this.email,
        city_id: this.city,
        inn: this.parseNumber(this.inn),
        entity: this.entity,
        branch_city_id: this.branch_city,
        source: this.source,
        income: this.parseNumber(this.income),
        years: this.years,
        clients_month: this.clients_month,
        clients_year: this.clients_year,
        clients_year_2: this.clients_year_2,
        income_2: this.parseNumber(this.income_2),
        text: this.text,
        jud1: this.jud1,
        jud2: this.jud2
      }

      // Сохранение в бд
      this.$store.commit('loading', true)
      this.axios
        .post(this.service, dataObj)
        .then(() => {
          this.$router.push(this.nextlink)
        })
        .catch((error) => {
          this.$store.commit('loading', false)
          this.error = error.response.data.error ?? 'server_error'
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
    }
  }
}
</script>
